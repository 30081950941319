import { Routes, Route } from "react-router-dom"
import HomePage from "../Pages/HomePage"
import '../App.css';
export const AuthRoutes=()=>{
    return(
        <div className="App">
        <Routes>
                <Route path="/" element={ <HomePage/> } />
        </Routes>
        </div>
    )
}