import "../helper/css/Header.css"
export const HeaderCmp=()=>{
    return(
     <div>
    <ul class="menu">
  <li><a href="#" class="active">Home</a></li>
  <li><a href="#">About</a></li>
  <li><a href="#">Portfolio</a></li>
  <li><a href="#">Contact</a></li>
  <li class="slider"></li>
</ul>
     </div>
    )
}