import logo from './logo.svg';
import './App.css';
import { FooterCmp } from './component/FooterCmp';
import { GalleryCmp, SecondCmp } from './component/MultiCmp';
import { BrowserRouter } from "react-router-dom";
import { AuthRoutes } from './Routes/AuthRoutes';

function App() {
  return (
    <BrowserRouter>
  <AuthRoutes/>
  </BrowserRouter>
  );
}

export default App;
