import Images from "../Constant/Images";
import { HeaderCmp } from "../component/HeaderCmp";
import "../helper/css/Global.css"

const LeftCmp=({index,header,description,image,tip})=>{
    return(
        <div className="row boxcontainer">
        <div className="headercss margincss">
           {index+1}: {header}
        </div>
        <div className="row margincss">
            <div className="col-6">
                <div className="desciptioncss">
                    {description}
                </div>
            </div>
            <div className="col-6 text-end">
                 <img src={image} className="imgcss" />
            </div>
        </div>
    <div className="tipcss margincss">
            {tip}
        </div>
    </div>
    )
}

const RightCmp=({index,header,description,image,tip})=>{
    return(
        <div className="row boxcontainer">
        <div className="headercss margincss">
           {index+1}: {header}
        </div>
        <div className="row margincss">
                        
                        <div className="col-6 text-start">
                             <img src={image} className="imgcss" />
                        </div>
                        <div className="col-6">
                            <div className="desciptioncss">
                                {description}
                            </div>
                        </div>
                    </div>
    <div className="tipcss margincss">
            {tip}
        </div>
    </div>
    )
}
const HomePage = () => {
    const homedata = [
        {
            header: "सुबह जल्दी उठें",
            image: Images.imgone,
            description: "सूर्योदय से पहले उठना अच्छा है, जब प्रकृति में प्रेमपूर्ण (सात्विक) गुण होते हैं जो मन की शांति और इंद्रियों में ताजगी लाते हैं। सूर्योदय मौसम के अनुसार अलग-अलग होता है, लेकिन औसतन वात वाले लोगों को सुबह 6 बजे, पित्त वाले लोगों को सुबह 5:30 बजे और कफ वाले लोगों को सुबह 4:30 बजे उठना चाहिए। जागने के तुरंत बाद, कुछ क्षणों के लिए अपने हाथों को देखें, फिर धीरे से हिलाएँ। उन्हें आपके चेहरे और छाती से लेकर कमर तक ऊपर रखें। इससे आभामंडल शुद्ध होता है।",
            tip: " हेल्थ टिप्स हिंदी में (Health Tips in Hindi) योगिक विज्ञान में कुछ ऐसे सरल उपाय हैं सेहत और स्वस्थ के लिए उत्तम हैं। जैसे – उपवास करना, उठने और बैठने के ढंग, और पानी भरकर रखने के लिए तांबे के बर्तनों का प्रयोग... इस ब्लॉग में पढ़ते हैं ऐसे ही कुछ तरीकों के पीछे छुप्पे विज्ञान के बारे में।"
        },
        {
            header: "गरारे करना",
            image: Images.imgfour,
            description: "दांतों, मसूड़ों और जबड़े को मजबूत बनाने, आवाज सुधारने और गालों की झुर्रियां हटाने के लिए दिन में दो बार गर्म तिल के तेल से गरारे करें। तेल को अपने मुँह में रखें, इसे ज़ोर से चारों ओर घुमाएँ, फिर इसे थूक दें और एक उंगली से मसूड़ों की धीरे से मालिश करें।",
            tip: ""
        },
        
        {
            header: "सुबह उठकर पानी पियें",
            image: Images.imgtwo,
            description: 'फिर कमरे के तापमान पर एक गिलास पानी पिएं, बेहतर होगा कि एक रात पहले भरे हुए शुद्ध तांबे के कप से। यह जीआई ट्रैक को धोता है, किडनी को साफ करता है और पेरिस्टलसिस को उत्तेजित करता है। दिन की शुरुआत चाय या कॉफी से करना अच्छा विचार नहीं है, क्योंकि इससे किडनी की ऊर्जा खत्म हो जाती है, एड्रिनल पर दबाव पड़ता है, कब्ज होता है और आदत बन जाती है।',
            tip: "शरीर हाइड्रेटेड रहता है|"
        },
        {
            header: "ध्यान",
            image: Images.imgfive,
            description: "सुबह-शाम कम से कम 15 मिनट ध्यान करना जरूरी है। जिस तरह से आप आदी हैं उसी तरह ध्यान करें, या खाली कटोरा ध्यान आज़माएँ। ध्यान आपके जीवन में संतुलन और शांति लाता है।",
            tip: "चिंता-तनाव को कम करने में सहायक।"
        },
        {
            header: " रोज एक फल खाने से मिलते हैं अनेक फायदे, जानें कैसे",
            image: Images.imgthree,
            description: "फल विटामिन, खनिज और डाइटरी फाइबर जैसे आवश्यक पोषक तत्वों से भरपूर होते हैं. वे पोषक तत्वों की एक विस्तृत श्रृंखला प्रदान करते हैं, जो शरीर के आवश्यक कार्यों के लिए जरूरी हैं. हमें ताजे फल खाने के कई स्वास्थ्य लाभों के बारे में बताया गया है और हममें से अधिकांश ने इस स्वास्थ्य सलाह का पालन किया है.",
            tip: "सुबह सबसे पहले केला खाने से शरीर में ऊर्जा भर जाती है. इसमें कार्बोहाइड्रेट और प्राकृतिक शर्करा अधिक होती है. इसमें पोटैशियम भी प्रचुर मात्रा में होता हैं|"
        },
       
        
        {
            header: "करी पत्ता का सेवन करने से बीमारियां होती है दूर",
            image: Images.imgsix,
            description: "करी पत्ते में कार्मिनेटिव गुण पाए जाते हैं, जो कब्ज जैसी समस्याओं को दूर रखते हैं। इससे पेट की समस्याओं में भी काफी आराम मिलता है। करी पत्ता ऐंटी बैक्टीरियल की तरह काम करता है जिसकी वजह से पेट की सारी समस्याएं दूर हो जाती हैं।",
            tip: "कढ़ी पत्ता शरीर में बैड कॉलेस्ट्रॉल को कंट्रोल करता है, जिससे हृदय स्वास्थ्य को बढ़ावा मिलता है। इसमें मौजूद विटामिन -सी, विटामिन-ए और विटामिन-ई दिल की सेहत के लिए फायदेमंद है।"
        },
    ]

    return (
        <div className="container">
            <HeaderCmp />
            {homedata.map((item, index) =>
             index%2==0?(  
                <LeftCmp index={index} 
                header={item?.header} 
                description={item?.description} 
                image={item?.image}
                tip={item?.tip}
                />
             ):(
                <RightCmp index={index} 
                header={item?.header} 
                description={item?.description} 
                image={item?.image}
                tip={item?.tip}
                />
             )
            )}

        </div>
    )
}

export default HomePage;